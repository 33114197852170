import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

const Index = ({ data, location }) => {
  const heroData = data.allHomepageYaml.edges[0].node.hero

  return (
    <>
      <MetaData location={location} />
      <Layout isHome={true}>
        <div className="container container--narrow pt-8 md:pt-16">
          <section className="mb-28">
            {/* <h1 className="mb-4">{heroData.title}</h1> */}

            <h1 className="lead mb-12" dangerouslySetInnerHTML={{__html: heroData.description}}>
            </h1>

            <h4 className="text-gray-600 mb-16 flex md:items-center">
              <span className="h3 mr-5 text-xl">👨🏼‍🚀</span>
              <span>{heroData.statusLine}</span>
            </h4>

            <div className="flex flex-col md:flex-row">
              <a href="/resume" className="button-neu md:mr-8">
                See My Resume
              </a>

              <a href="mailto:hi@wesdearborn.com" className="button-neu mt-6 md:mt-0">
                Contact Me
                <span class="ml-2">&#8599;</span>
              </a>
            </div>
          </section>

          {/* <section>
            <h1>I Do...</h1>

            <div className="grid grid-cols-2 gap-10">
              <div>
                <h3>Product Management</h3>
              </div>

              <div>
                <h3>UX & UI Design</h3>
              </div>

              <div>
                <h3>Front-end Development</h3>
              </div>

              <div>
                <h3>Conversion Optimization</h3>
              </div>
            </div>
          </section> */}
        </div>
      </Layout>
    </>
  )
}

export default Index

export const homepageQuery = graphql`
  query HomepageYamlQuery {
    allHomepageYaml {
      edges {
        node {
          hero {
            description
            title
            statusLine
          }
        }
      }
    }
  }
`